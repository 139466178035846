import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ApiResponse } from '../models/types';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UrlParams } from '../models/UrlParams';
import { PageStateService } from './page-state.service';

@Injectable({
  providedIn: 'root'
})
export class PageControlService {

  /**
   * Uses to emit page data
   * received with callback function
   * to component subscribers
   */
  private pageChangesSubscriber: Subscriber<any>;

  /**
   * Callback function passed from component for retrieving page data
   */
  private requestMethod: (params: UrlParams, ...args: any[]) => Observable<ApiResponse<any>>;

    /**
     * Params for the callback method
     */
  private requestParams: any[];

  /**
   * Page content update event
   */
  public pageUpdate$ = new EventEmitter();

  /**
   * Force disables pagination
   */
  public isDisablePagination = false;

  /**
   * Observable that emits API response
   * on page update
   */
  pageChanges$ = new Observable<ApiResponse<any>>(observer => {
    const self = this;
    this.pageChangesSubscriber = observer;

    return { unsubscribe() {
        self.requestMethod = null;
        self.isDisablePagination = false;
        self.pageState.setUrlParams(); // reset pagination
      }};
  });

  /**
   * Page Service constructor
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public pageState: PageStateService
  ) {
    activatedRoute.queryParams.subscribe((val: any) => {
      this.pageRefresh();
    })
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
       // this.pageRefresh();
      }
    });
  }

  /**
   * Returns whether paging in this state available
   */
  get isEnabled() {
    return !!this.requestMethod;
  }

  /**
   * Accepts a callback function
   * that will be executed to retrieve page data.
   * Parameters of the current page state will be passed to the callback function
   */
  pageChanges<T>(callback: (params: UrlParams, ...args: any[]) => Observable<ApiResponse<T>>, ...params: any[]):
    Observable<ApiResponse<T>> {
    this.requestMethod = callback;
    this.requestParams = params;
    this.pageRefresh();

    return this.pageChanges$;
  }

  /**
   * Sends a request to get page data
   * with URL parameters
   */
  pageRefresh() {
    if (!this.isEnabled) {

      return;
    }

    this.requestMethod(this.activatedRoute.snapshot.queryParams, ...this.requestParams).subscribe(response => {
      this.pageState.total = response.total;
      this.pageState.setUrlParams(this.activatedRoute.snapshot.queryParams);
      this.pageChangesSubscriber.next(response);
      this.pageUpdate$.emit();
      }
    );
  }

  /**
   * Calls route update
   */
  navigate(goToFirstPage = false) {

    if (goToFirstPage) {
      this.pageState.page = 1; // force set first page
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: this.pageState.getUrlParams(),
        // queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }
}
