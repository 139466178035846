<div class="modal-header" *ngIf="sysid">
  <h4 class="modal-title" (click)="data.copyToClipboard(sysid)">
    <button title="Copy to clipboard" type="button" class="btn btn-sm py-0 btn-link">
      <i class="fa fa-clipboard" aria-hidden="true"></i>
    </button>
    Health Data: {{sysid}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div style="position: relative; height: 500px">
    <ngx-charts-line-chart
      style="position: absolute; left: 0; top: 0; height: 100%; width: 100%"
      [scheme]="colorScheme"
      [legend]="true"
      [showXAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="false"
      [xAxisLabel]="'Time'"
      [yAxisLabel]="'Status'"
      [timeline]="true"
      [results]="results"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div style="padding: 5px 5px;">
          {{model.series}} &bull; {{model.name | date : 'dd-MM-yyyy HH:mm'}}
          <br>{{model.value ? 'Yes' : 'No'}}
        </div>
      </ng-template>
      <ng-template #seriesTooltipTemplate let-model="model">
        <div style="padding: 5px 5px; text-align: left;">
          <div *ngFor="let legend of model">
            <span style="display: inline-block; width: 10px; height: 10px; border-radius: 3px;" [ngStyle]="{'background-color': legend.color}"></span>
            &nbsp;
            <span>{{legend.series}}: {{legend.value ? 'Yes' : 'No'}}</span>
          </div>
        </div>
      </ng-template>
    </ngx-charts-line-chart>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Cancel</button>
</div>

