import { Injectable } from '@angular/core';
import { Building } from '../models/Building';
import { BuildingService } from './api/building.service';
import { finalize } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  private buildingsSubject$: ReplaySubject<Building[]> = new ReplaySubject();
  private isLoadingBuildings = false;
  private isLoadedBuildings = false;

  constructor(private buildingService: BuildingService, private toastr: ToastrService) {

  }

  public copyToClipboard(copyText) {
    if (!navigator['clipboard']) {
      this.toastr.error('Sorry, but your browser does not support this feature!');
    } else {
      navigator['clipboard'].writeText(copyText).then(() => {
        this.toastr.success(`<strong>${copyText}</strong>` + ' is copied', null, {
          enableHtml: true
        });
      }).catch((err) => {
        this.toastr.error(err);
      });
    }
  }

  /**
   * Loads buildings data
   * if it has not been loaded before.
   */
  private loadBuildings() {
    if (!this.isLoadedBuildings && !this.isLoadingBuildings) {
      this.isLoadingBuildings = true;

      this.buildingService.getBuildingsSelect().pipe(
          finalize(() => this.isLoadingBuildings = false)
      ).subscribe(result => {
        this.buildingsSubject$.next(result.entities);
        this.isLoadedBuildings = true;
      }, () => {
        this.buildingsSubject$.next([]);
        this.isLoadedBuildings = true;
      });
    }
  }

  /**
   * Returns Observable of buildings list
   */
  get buildings$() {
    this.loadBuildings();

    return this.buildingsSubject$.asObservable();
  }
}
