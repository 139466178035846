import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BannerComponent } from './banner/banner.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule } from '@angular/forms';
import { PaginationComponent } from './pagination/pagination.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import {ScreenConfigurationComponent} from "./screen-configuration/screen-configuration.component";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {HealthDataModalComponent} from "./health-data-modal/health-data-modal.component";
import {DirectivesModule} from "../directives/directives.module";

@NgModule({
  declarations: [
    LayoutComponent,
    BannerComponent,
    FooterComponent,
    SidebarComponent,
    PaginationComponent,
    ScreenConfigurationComponent,
    HealthDataModalComponent
  ],
    imports: [
        CommonModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        ComponentsModule,
        NgxChartsModule,
        DirectivesModule,
    ],
  entryComponents: [
    ScreenConfigurationComponent,
    HealthDataModalComponent
  ],
  exports: [
    LayoutComponent
  ],
  providers: [
    DatePipe
  ]
})
export class UiModule { }
