import {Injectable} from '@angular/core';
import {ApiBaseService} from './api-base.service';
import {Observable} from 'rxjs';
import {DisplayUptime} from '../../models/DisplayUptime';
import {UrlParams} from '../../models/UrlParams';
import {ApiResponse} from '../../models/types';
import {boundMethod} from 'autobind-decorator';

@Injectable({
  providedIn: 'root'
})
export class NetworkUptimeService {

  /**
   * Base service path
   */
  private servicePath = 'health';

  constructor(
    private http: ApiBaseService,
  ) {
  }

  @boundMethod
  getDisplayStat(params: UrlParams = null): Observable<ApiResponse<DisplayUptime>> {
    return this.http.get(this.servicePath, params);
  }

  getHealthData(screenId: number, dateFrom: string, dateTo: string): Observable<ApiResponse<any>> {
    if (!dateFrom || !dateTo) {
      throw new Error('Request failed. Empty date interval.');
    }
    return this.http.get(`${this.servicePath}/${screenId}/data`, {dateFrom: dateFrom, dateTo: dateTo});
  }
}
