import { Component, OnInit } from '@angular/core';
import { PageStateService } from '../../services/page-state.service';
import { DatePipe } from '@angular/common';
import { UrlParams } from '../../models/UrlParams';
import { AuthenticationService } from '../../services/api/authentication.service';
import { TitleService } from '../../services/title.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [ DatePipe ]
})
export class SidebarComponent implements OnInit {

  /**
   * Sidebar state
   */
  isCollapsed = false;

  public cmsUrl = environment.cmsUrl;

  /**
   * Max screen width for mobile devices todo: move to config?
   */
  private mobileScreenWidthThreshold = 767;

  constructor(
      public pageState: PageStateService,
      private datePipe: DatePipe,
      public auth: AuthenticationService,
      public titleService: TitleService
  ) {
    // hide sidebar on init for mobile devices
    this.isCollapsed = window.screen.width <= this.mobileScreenWidthThreshold;
  }

  ngOnInit() {
  }

  /**
   * Returns default date interval
   * for network uptime stat data
   */
  get networkUptimeDateInterval(): UrlParams {
    const dateFrom = new Date().setDate(new Date().getDate() - 30);
    const dateTo = new Date().setDate(new Date().getDate() - 1);

    return {
      dateFrom: this.datePipe.transform(dateFrom, 'dd-MM-yyyy'),
      dateTo: this.datePipe.transform(dateTo, 'dd-MM-yyyy')
    };
  }

  collapseOnMobile() {
    if (window.screen.width <= this.mobileScreenWidthThreshold) {
      this.toggle(false);
    }
  }

  /**
   * Toggle sidebar
   */
  toggle(state?: boolean) {
    this.isCollapsed = typeof state === 'boolean' ? !state : !this.isCollapsed;
  }
}
