import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {BuildingsModule} from "./ui/buildings/buildings.module";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/health-monitor',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'health-monitor',
        canActivate: [AuthGuard],
        loadChildren: './ui/health-monitor/health-monitor.module#HealthMonitorModule',
        data: {
          breadcrumb: 'Health Monitor'
        }
      },
      {
        path: 'network-uptime',
        canActivate: [AuthGuard],
        loadChildren: './ui/network-uptime/network-uptime.module#NetworkUptimeModule',
        data: {
          breadcrumb: 'Network Uptime'
        }
      },
      {
        path: 'dwl',
        canActivate: [AuthGuard],
        loadChildren: './ui/dwl/dwl.module#DwlModule',
        data: {
          breadcrumb: 'Download stats'
        }
      },
      {
        path: 'gallery',
        canActivate: [AuthGuard],
        loadChildren: './ui/gallery/gallery.module#GalleryModule',
        data: {
          breadcrumb: 'Gallery'
        }
      },
      {
        path: 'frameshots',
        canActivate: [AuthGuard],
        loadChildren: './ui/frameshots/frameshots.module#FrameshotsModule',
        data: {
          breadcrumb: 'Frameshots'
        }
      },
      {
        path: 'screenshots',
        canActivate: [AuthGuard],
        loadChildren: './ui/screenshots/screenshots.module#ScreenshotsModule',
        data: {
          breadcrumb: 'Screenshots'
        }
      },
      {
        path: 'buildings',
        canActivate: [AuthGuard],
        loadChildren: './ui/buildings/buildings.module#BuildingsModule',
        data: {
          breadcrumb: 'Buildings'
        }
      },
      {
        path: 'displays',
        canActivate: [AuthGuard],
        loadChildren: './ui/displays/displays.module#DisplaysModule',
        data: {
          breadcrumb: 'Displays'
        }
      },
      {
        path: 'building-wizard',
        canActivate: [AuthGuard],
        loadChildren: './ui/building-wizard/building-wizard.module#BuildingWizardModule',
        data: {
          breadcrumb: 'Building Wizard'
        }
      },
    ],
  },
  {
    path: 'login',
    loadChildren: './ui/login/login.module#LoginModule',
    data: {
      breadcrumb: 'Login'
    }
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
