import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import {Building, BuildingModel} from '../../models/Building';
import { UrlParams } from '../../models/UrlParams';
import { ApiResponse } from '../../models/types';
import {DisplayExtend} from "../../models/Display";

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  private servicePath = 'building';

  constructor(
    private http: ApiBaseService,
  ) { }

  getBuildingsSelect(params: UrlParams = null): Observable<ApiResponse<Building>> {
    params = {...params, ...{'per-page': 1000, 'order-by': 'address|asc'}};
    return this.http.get(this.servicePath, params);
  }

  getBuildingsList(params: UrlParams = null): Observable<ApiResponse<Building>> {
  //  params = {...params, ...{'order-by': 'id|desc'}};
    return this.http.get(this.servicePath, params);
  }

  getTimezones(): Observable<ApiResponse<{ timezone: string }>> {
    return this.http.get(`${this.servicePath}/timezone`);
  }

  getCountries(): Observable<ApiResponse<{ country: string }>> {
    return this.http.get(`${this.servicePath}/countries`);
  }

  getCities(): Observable<ApiResponse<{id: number, city: string, province: string }>> {
    return this.http.get(`${this.servicePath}/cities`);
  }

  getPmiUsers(): Observable<ApiResponse<any>> {
    return this.http.get('pmi/users');
  }

  getBuilding(buildingId: number): Observable<BuildingModel> {
    return this.http.get(`${this.servicePath}/${buildingId}`) as any;
  }

  createBuilding(data: Object): Observable<BuildingModel> {
    return this.http.post(`${this.servicePath}`, data) as any;
  }

  createDisplay(buildingId: number, data: Object): Observable<DisplayExtend> {
    return this.http.post(`${this.servicePath}/${buildingId}/display`, data) as any;
  }

  updateBuilding(buildingId: number, data: Object): Observable<BuildingModel> {
    return this.http.patch(`${this.servicePath}/${buildingId}`, data) as any;
  }

  deleteBuilding(buildingId: number): Observable<null> {
    return this.http.delete(`${this.servicePath}/${buildingId}`) as any;
  }

  createWizard(data: Object): Observable<BuildingModel> {
    return this.http.post(`${this.servicePath}/wizard`, data) as any;
  }
}
