import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import {User} from "../../models/User";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    /**
     * Base service path
     */
    private servicePath = 'user';

    constructor(
        private http: ApiBaseService,
    ) {}

    me(): Observable<User> {
        return this.http.get(`${this.servicePath}/me`) as any;
    }
}
