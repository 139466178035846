import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {map} from "rxjs/operators";
import {UserService} from "./user.service";
import {User} from "../../models/User";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private _user = new BehaviorSubject<User>(null);
  public readonly currentUser = this._user.asObservable();

  constructor(private router: Router,
             private userService: UserService
  ) {
    // this._user = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
  }

  public get currentUserValue(): User {
    return this._user.value;
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  login(username: string, password: string) {
    const token = btoa(`${username}:${password}`);
    // const user = new AuthUser(username, btoa(`${username}:${password}`));
    this.setToken(token);
    return this.getProfile();
  }

  public getProfile() {
   return this.userService.me().pipe(
      map((res) => {
        this._user.next(res);
        return res;
      })
    )
  }

  public checkUser(): Observable<boolean> {
    //return of(!!this.currentUserValue.token)
    if (this.getToken()) {
      return this.getProfile().pipe(map(user => true));
    }
    return of(false);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this._user.next(null);
    this.router.navigate(['/login']);
  }
}
