import { Component, OnInit } from '@angular/core';
import { PageControlService } from '../../services/page-control.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  pageSizeOptions = [15, 20, 30, 50, 100];

  constructor(public pageControlService: PageControlService) { }

  ngOnInit() {}

  get listedFrom() {
    return ((this.pageControlService.pageState.page - 1) * this.pageControlService.pageState.pageSize) + 1;
  }

  get listedTo() {
    const lastItemNumber = this.pageControlService.pageState.page * this.pageControlService.pageState.pageSize;
    return lastItemNumber > this.pageControlService.pageState.total ? this.pageControlService.pageState.total : lastItemNumber;
  }
}
