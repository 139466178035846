import { Params } from '@angular/router';

export class UrlParams implements Params {
  orderBy?: string = null;
  pageSize?: number = null;
  'per-page'?: number = null;
  page?: number = null;
  buildingId?: number = null;
  query?: string = null;
  healthStatus?: string = undefined;
  screenshotStatus?: string = null;
  state?: string = undefined;
  status?: string = null;
  isKnownStatus?: string = null;
  isSnooze?: string = null;
  dateFrom?: string = null;
  dateTo?: string = null;
  displayId?: number = null;

  versions?: string = null;
}
