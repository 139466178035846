// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appTitle: 'HealthMonitor (dev)',
  production: false,
 // apiHost: 'http://hm-api:8080',
  apiHost: 'https://dev-hm-api.ubconnex.ca',
  baseApiUrl: 'api',
  cmsUrl: 'https://ubconnex.ca/cms',
  rateCardUrl: 'https://ratecards.ubconnex.ca/ratecards/info?building_id='
};
