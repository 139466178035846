import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private titleSeparator = ' / ';
  private breadcrumbs = [];

  constructor(private titleService: Title) { }

  /**
   * Sets app title
   */
  setTitle(title: string) {
    title = (title ? title + this.titleSeparator : '') + environment.appTitle;
    this.titleService.setTitle(title);
  }

  /**
   * Sets app title based on breadcrumbs parameter
   */
  setBreadcrumbs(breadcrumbs: string[]) {
    this.breadcrumbs = breadcrumbs;
    this.setTitle(breadcrumbs.join(this.titleSeparator));
  }

  /**
   * Returns app title
   */
  get title() {
    return this.titleService.getTitle();
  }

  /**
   * Returns title for current breadcrumbs
   */
  get breadcrumbsTitle() {
    return this.breadcrumbs.join(this.titleSeparator);
  }
}
