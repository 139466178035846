import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, of, throwError} from 'rxjs';
import {catchError, finalize, map, retry, switchMap, tap} from 'rxjs/operators';
import {ApiResponse} from '../../models/types';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {UrlParams} from '../../models/UrlParams';
import {ApiUrlParams} from '../../models/ApiUrlParams';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {

  private apiVersion = 1;

  private requestsInProgressCount = 0;

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Returns API request URL
   */
  getRequestUrl(servicePath): string {
    return `${environment.apiHost}/${environment.baseApiUrl}/v${this.apiVersion}/${servicePath}`;
  }

  /**
   * Send GET API request
   */
  get<T>(servicePath, params?: UrlParams): Observable<ApiResponse<T>> {
    return this.request('get', servicePath, params);
  }

  /**
   * Send PATCH API request
   */
  post(servicePath, body?, params?: UrlParams) {
    return this.request('post', servicePath, params, body);
  }

  /**
   * Send PATCH API request
   */
  patch(servicePath, body?, params?: UrlParams) {
    return this.request('patch', servicePath, params, body);
  }

  /**
   * Send DELETE API request
   */
  delete(servicePath, params?: UrlParams) {
    return this.request('delete', servicePath, params);
  }

  /**
   * Send API request
   * and process server response
   */
  request(method, servicePath, params: UrlParams = null, requestBody = null): Observable<ApiResponse<any>> {
    const httpParams = new ApiUrlParams(params).getHttpParams();

    return of(true).pipe(
      switchMap(val => {
        this.requestsInProgressCount++;
        return this.http.request(method, this.getRequestUrl(servicePath), {params: httpParams, body: requestBody}).pipe(
          retry(1),
          finalize(() => {
            this.requestsInProgressCount--;
          }),
          map(this.processResponse.bind(this)),
        )
      })
    );
  }

  /**
   * Process API response
   */
  processResponse(response: ApiResponse<any>): ApiResponse<any> {
    return response;
  }



  /**
   * Defines whether in progress any API requests
   */
  get isBusy() {
    return this.requestsInProgressCount > 0;
  }
}
