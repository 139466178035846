import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Display} from "../../models/Display";
import {DisplayService} from "../../services/api/display.service";
import {AppDataService} from "../../services/app-data.service";

@Component({
  selector: 'app-screen-configuration',
  templateUrl: './screen-configuration.component.html',
  styleUrls: ['./screen-configuration.component.scss'],
})
export class ScreenConfigurationComponent implements OnInit {

  @Input() tab: string;
  @Input() displayId: number;

  public screen: Display;

  constructor(
    private displayService: DisplayService,
    public data: AppDataService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.displayService.getDisplay(this.displayId).subscribe(res => this.screen = res);
  }
}
