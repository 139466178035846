import {Component, Input, OnInit} from "@angular/core";
import {AppDataService} from "../../services/app-data.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NetworkUptimeService} from "../../services/api/network-uptime.service";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-health-data-modal',
  templateUrl: './health-data-modal.component.html',
  styleUrls: ['./health-data-modal.component.scss'],
})
export class HealthDataModalComponent implements OnInit{

  @Input() id: number;
  @Input() sysid: string;
  @Input() dateFrom: string;
  @Input() dateTo: string;
  public items = [];
  results: any[] = [];
  colorScheme = {
    domain: ['#090', '#00F', '#FF0']
  };

  constructor(
    private networkUptimeService: NetworkUptimeService,
    public data: AppDataService,
    public activeModal: NgbActiveModal,
  ) {
  }


  ngOnInit() {
    this.getData();
  }

  private getData() {
    this.networkUptimeService.getHealthData(this.id, this.dateFrom, this.dateTo).subscribe(res => {
      this.items = res.entities;

      const netRow =  {
        'name': 'Internet',
        'series': []
      };

      const browserRow =  {
        'name': 'Browser',
        'series': []
      };

      const xservRow =  {
        'name': 'Xserv',
        'series': []
      };
      res.entities.forEach(e => {
        const name =  new Date(e.time);
        netRow.series.push({name: name, value: e.net})
        browserRow.series.push({name: name, value: e.browser * 2})
        xservRow.series.push({name: name, value: e.xserv * 3 })
      });

      this.results = [netRow, browserRow, xservRow]
    });
  }
}
