import { Component } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TitleService } from './services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: TitleService
  ) {
    this.subscribeNavigationEnd();
  }

  /**
   * Subscribes on route navigation event
   * to retrieve breadcrumbs details
   * for activated router
   */
  subscribeNavigationEnd() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        const breadcrumbs = [];
        let route = this.route;

        while (route.firstChild) {
          route = route.firstChild;

          if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.breadcrumb) {
            breadcrumbs.push(route.routeConfig.data.breadcrumb);
          }
        }

        return breadcrumbs;
      })
    ).subscribe(breadcrumb => {
      this.titleService.setBreadcrumbs(breadcrumb);
    });
  }
}
