import { Injectable } from '@angular/core';
import { UrlParams } from '../models/UrlParams';
import { DEFAULT_PAGE_PARAMETERS } from '../models/ApiUrlParams';

@Injectable({
  providedIn: 'root'
})
export class PageStateService extends UrlParams {

  /**
   * Results count
   */
  total = 0;

  constructor() {
    super();
    Object.assign(this, DEFAULT_PAGE_PARAMETERS);
  }

  /**
   * Returns url parameters
   * of current state
   */
  getUrlParams() {
    const params = new UrlParams();
    Object.getOwnPropertyNames(params).map(paramName => {
      params[paramName] = this[paramName];
    });

    return params;
  }

  /**
   * Sets URL parameters
   * of current page state
   * OR reset parameters to defaults if empty arg
   */
  setUrlParams(params?: UrlParams): void {
    params = params || this.getProtectedParams();
    Object.assign(this, new UrlParams(), DEFAULT_PAGE_PARAMETERS, params);
  }

  /**
   * Returns URL parameters protected for reset.
   */
  public getProtectedParams(params?: UrlParams): UrlParams {
    const {buildingId, pageSize} = this;
    return {buildingId, pageSize, ...params};
  }
}
