<div *ngIf="!pageControlService.isDisablePagination && pageControlService.isEnabled && pageControlService.pageState.total" class="wrapper">
    <div class="size">
        <div class="input-group page-size-select mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Page size</span>
            </div>
            <select [(ngModel)]="pageControlService.pageState.pageSize"
                    (change)="pageControlService.navigate(true)"
                    class="custom-select"
                    id="inputGroupSelect01">
                <option *ngFor="let size of pageSizeOptions" value="{{size}}">{{size}}</option>
                <option value="10000">All</option>
            </select>
        </div>
    </div>
    <div class="paging">
        <ngb-pagination class="d-flex justify-content-center"
                        [collectionSize]="pageControlService.pageState.total"
                        [(page)]="pageControlService.pageState.page"
                        (pageChange)="pageControlService.navigate()"
                        [rotate] = "true"
                        [maxSize]="4"
                        [pageSize]="pageControlService.pageState.pageSize">
        </ngb-pagination>
    </div>
    <div class="details">
        <p class="text-right text-secondary pt-2">
            {{listedFrom}} - {{listedTo}} of {{pageControlService.pageState.total}}
        </p>
    </div>
</div>
