<section *ngIf="auth.currentUserValue" id="sidebar" [ngClass]="{ collapsed: isCollapsed }">

  <header>
    <a class="nav-toggle nav-link" (click)="toggle()"><i class="fa fa-bars" aria-hidden="true"></i></a>
    <span class="title">{{titleService.breadcrumbsTitle}}</span>
  </header>

  <nav class="menu">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/health-monitor"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-heartbeat" aria-hidden="true"></i>
          <span class="description">Health Monitor</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/network-uptime"
           [queryParams]="pageState.getProtectedParams(networkUptimeDateInterval)"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-tachometer" aria-hidden="true"></i>
          <span class="description">Network Uptime</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/dwl"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-cloud-download" aria-hidden="true"></i>
          <span class="description">Download stats</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/gallery"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-picture-o" aria-hidden="true"></i>
          <span class="description">Gallery</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/frameshots"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-film" aria-hidden="true"></i>
          <span class="description">Frameshots</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/screenshots"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-file-movie-o" aria-hidden="true"></i>
          <span class="description">Proof Of Play</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/buildings"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize] }"
           routerLinkActive="active">
          <i class="fa fa-building" aria-hidden="true"></i>
          <span class="description">Buildings</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/displays"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize, pageState.buildingId] }"
           routerLinkActive="active">
          <i class="fa fa-television" aria-hidden="true"></i>
          <span class="description">Displays</span>
        </a>
      </li>

      <li *hasRole="['manager']" class="nav-item">
        <a class="nav-link"
           (click)="collapseOnMobile()"
           routerLink="/building-wizard"
           [queryParams]="pageState.getProtectedParams()"
           [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: [pageState.pageSize] }"
           routerLinkActive="active">
          <i class="fa fa-puzzle-piece" aria-hidden="true"></i>
          <span class="description">Building Wizard</span>
        </a>
      </li>

      <div class="dropdown-divider"></div>
      <li class="nav-item">
        <a class="nav-link" href="{{cmsUrl}}/?a=232" target="_blank">
          <i class="fa fa-wrench" aria-hidden="true"></i>
          <span class="description">CMS Tools</span>
        </a>
      </li>
      <!--
		  <li class="nav-item">
			<a class="nav-link">
			  <i class="fa fa-google" aria-hidden="true"></i>
			  <span class="description">Ads Status</span>
			</a>
		  </li>
		  <li class="nav-item">
			<a class="nav-link">
			  <i class="fa fa-comment" aria-hidden="true"></i>
			  <span class="description">Notices Status</span>
			</a>
		  </li>
	  -->
    </ul>

    <ul class="nav user-details">
      <li class="nav-item">
        <a (click)="auth.logout(); collapseOnMobile()" class="nav-link link">
          <i class="fa fa-sign-out" aria-hidden="true"></i>
          <span class="description">Logout ({{auth.currentUserValue.username}})</span>
        </a>
      </li>
    </ul>
  </nav>

  <div class="backdrop" (click)="toggle(false)"></div>

</section>
