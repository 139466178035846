<div class="modal-header" *ngIf="screen">
  <h4 class="modal-title" (click)="data.copyToClipboard(screen.sysId)">
    <button title="Copy to clipboard" type="button" class="btn btn-sm py-0 btn-link">
      <i class="fa fa-clipboard" aria-hidden="true"></i>
    </button>
    {{screen.sysId}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="screen">
  <ngb-tabset #t="ngbTabset" [activeId]="tab" justify="justified">
    <ngb-tab id="cnf">
      <ng-template ngbTabTitle>Config</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe [src]="'https://screenshots.ubconnex.ca/'+screen.sysId+'.cnf' | safe"></iframe>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab id="xrn">
      <ng-template ngbTabTitle>XRANDR</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe  [src]="'https://screenshots.ubconnex.ca/'+screen.sysId+'.xrn' | safe"></iframe>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab id="net">
      <ng-template ngbTabTitle>NET</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe  [src]="'https://screenshots.ubconnex.ca/'+screen.sysId+'.net' | safe "></iframe>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="settings">
      <ng-template ngbTabTitle>Settings</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe  [src]="'https://internal.ubconnex.ca/media/'+screen.sysId+'.settings' | safe "></iframe>
        </div>
      </ng-template>
    </ngb-tab>
     <ngb-tab id="local">
      <ng-template ngbTabTitle>Local</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe  [src]="'https://internal.ubconnex.ca/media/'+screen.sysId+'.local' | safe "></iframe>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="err">
      <ng-template ngbTabTitle>Dwl Errors</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe  [src]="'https://screenshots.ubconnex.ca/'+screen.sysId+'.err' | safe "></iframe>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="files">
      <ng-template ngbTabTitle>Files</ng-template>
      <ng-template ngbTabContent>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe  [src]="'https://screenshots.ubconnex.ca/'+screen.sysId+'.md5' | safe "></iframe>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Cancel</button>
</div>

