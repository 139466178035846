import { NgModule } from '@angular/core';
import { OrderDirective } from './order.directive';
import {HasRoleDirective} from "./hasRole.directive";

@NgModule({
  declarations: [
    OrderDirective,
    HasRoleDirective
  ],
  exports: [
    OrderDirective,
    HasRoleDirective
  ]
})
export class DirectivesModule { }
