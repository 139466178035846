import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {Role, User} from "../models/User";
import {AuthenticationService} from "../services/api/authentication.service";

@Directive({
  selector: '[hasRole]',
})

export class HasRoleDirective implements OnInit {
  private currentUser: User;
  private permissions = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private auth: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.currentUser = this.auth.currentUserValue;
    this.updateView();
  }

  @Input()
  set hasRole(val: string[]) {
    this.permissions = val.map(v => `ROLE_${v}`);
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (this.currentUser && this.currentUser.role) {
      if (this.currentUser.role == 'ROLE_ADMIN') {
        return true;
      }
      return this.permissions.some(p => p.toUpperCase() == this.currentUser.role.toUpperCase());
    }
    return false;
  }
}
