import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BuildingFilterComponent} from './building-filter/building-filter.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {DropdownCheckComponent} from './dropdown-check/dropdown-check.component';
import {FileUploaderComponent} from './file-uploader/file-uploader.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDatePipe} from '../../pipes/ngb-date.pipe';
import {NgxUploaderModule} from 'ngx-uploader';
import {QueryFilterComponent} from "./query-filter/query-filter.component";
import {TimeAgoPipe} from "../../pipes/time-ago.pipe";
import {SafePipe} from "../../pipes/safe.pipe";
import {DwlValPipe} from "../../pipes/dwlVal.pipe";
import {ObjectKeysPipe} from "../../pipes/object-keys.pipe";
import {HumancasePipe} from "../../pipes/humancase.pipe";
import {AgmCoreModule} from "@agm/core";
import {KeyValuePipe} from "../../pipes/key-value.pipe";

@NgModule({
  declarations: [
    BuildingFilterComponent,
    QueryFilterComponent,
    DatePickerComponent,
    DropdownCheckComponent,
    FileUploaderComponent,
    SpinnerComponent,
    NgbDatePipe,
    TimeAgoPipe,
    SafePipe,
    DwlValPipe,
    ObjectKeysPipe,
    HumancasePipe,
    KeyValuePipe
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    NgbDatepickerModule,
    NgxUploaderModule,
    NgbModule
  ],
  exports: [
    KeyValuePipe,
    ObjectKeysPipe,
    HumancasePipe,
    DwlValPipe,
    SafePipe,
    TimeAgoPipe,
    NgbDatePipe,
    SpinnerComponent,
    DatePickerComponent,
    BuildingFilterComponent,
    QueryFilterComponent,
    DropdownCheckComponent,
    FileUploaderComponent
  ]
})
export class ComponentsModule {
}
