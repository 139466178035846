import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/api/authentication.service';
import {ToastrService} from "ngx-toastr";
import {catchError} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  /**
   * Handle API error
   */
  handleError(error) {
    let errorMessage = '';
    let titleMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      titleMessage = 'Server Response error' + (error.status ? ` (${error.status})` : '');
      errorMessage = `Message: ${error.message}`;

      if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout(); // Unauthorized
      }
    }

    this.toastr.error(errorMessage, titleMessage);

    return throwError(errorMessage);
  }
}
