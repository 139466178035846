import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiBaseService } from '../../services/api/api-base.service';
import { PageControlService } from '../../services/page-control.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  @ViewChild('content', { static: true }) contentEl: ElementRef;

  /**
   * Indicates whether the router config is loading
   */
  isRouteConfLoading = false;

  isLoading = true;

  constructor(
      public api: ApiBaseService,
      private pageControlService: PageControlService,
      private router: Router
  ) { }

  ngOnInit() {
    // scroll page to the top on every content updates
    this.pageControlService.pageUpdate$
        .subscribe(() => this.scrollToTop());

    /**
     * On router loading events
     */
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.isRouteConfLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isRouteConfLoading = false;
      }
    });

    setInterval(() => {
      this.isLoading = this.isRouteConfLoading || this.api.isBusy;
    }, 100);
  }

  /**
   * Scroll page content block
   * to the top
   */
  scrollToTop() {
    this.contentEl.nativeElement.scrollTo(0, 0);
  }

  /**
   * Defines whether app loading data from server
   */
 /* get isLoading() {
    return
  }*/
}
