import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/api/authentication.service';
import {catchError, tap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let curUser = this.authenticationService.checkUser();
    return curUser.pipe(tap((res) => {
      if (!res) {
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      }
      return of(res);
    }));
  }
}
