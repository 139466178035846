import { HttpParams } from '@angular/common/http';
import { UrlParams } from './UrlParams';

export const DEFAULT_PAGE_PARAMETERS: UrlParams = {
  pageSize: 100,
  page: 1
};

const URL_PARAMS_MAPPING: {[key in Extract<keyof UrlParams, string >]?: string} = {
  orderBy: 'order-by',
  pageSize: 'per-page'
};

export class ApiUrlParams {

  constructor(urlParams: UrlParams = {}) {
    // merge with default parameters
    urlParams = Object.assign({}, DEFAULT_PAGE_PARAMETERS, urlParams);

    // convert app URL parameters to API
    Object.getOwnPropertyNames(urlParams).forEach(propertyName => {
      if (urlParams[propertyName]) {
        const apiPropertyName = URL_PARAMS_MAPPING[propertyName] || propertyName;
        this[apiPropertyName] = urlParams[propertyName];
      }
    });
  }

  /**
   * Returns HttpParams object
   */
  getHttpParams(): HttpParams {
    let httpParams = new HttpParams();

    Object.getOwnPropertyNames(this).map(propertyName => {
      httpParams = httpParams.set(propertyName, this[propertyName]);
    });

    return httpParams;
  }
}
