import {Injectable} from '@angular/core';
import {ApiBaseService} from './api-base.service';
import {UrlParams} from '../../models/UrlParams';
import {boundMethod} from 'autobind-decorator';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../models/types';
import {Display, DisplayExtend} from '../../models/Display';
import {ScreenHealth} from '../../models/ScreenHealth';
import {ScreenHealthNote} from "../../models/ScreenHealthNote";
import {ScreenItem} from "../../models/ScreenItem";
import {DisplayHistory} from "../../models/DisplayHistory";

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  /**
   * Base service path
   */
  private servicePath = 'display';

  constructor(
    private http: ApiBaseService,
  ) {
  }

  getDisplaysSelect(params: UrlParams = null): Observable<ApiResponse<Display>> {
    return this.http.get(this.servicePath, params);
  }

  getDisplaysList(params: UrlParams = null): Observable<ApiResponse<Display>> {
    return this.http.get(this.servicePath, params);
  }

  /**
   * Returns display details
   * for selected display displayId
   */
  getDisplay(displayId: number): Observable<DisplayExtend> {
    return this.http.get(`${this.servicePath}/${displayId}`) as any;
  }

  /**
   * Patch display details
   * for selected display displayId
   */
  updateDisplay(displayId: number, data: Object): Observable<DisplayExtend> {
    return this.http.patch(`${this.servicePath}/${displayId}`, data) as any;
  }

  /**
   * Sends command to reboot the display
   */
  cmdDisplay(displayId: number, commandId: number): Observable<ScreenItem> {
    return this.http.get(`${this.servicePath}/${displayId}/command/${commandId}`) as any;
  }

  /**
   * Returns screen notes list
   */
  getDisplayNotes(screenId: number): Observable<ApiResponse<ScreenHealthNote>> {
    return this.http.get(`${this.servicePath}/${screenId}/comment`);
  }

  deleteDisplay(displayId): Observable<any> {
    return this.http.delete(`${this.servicePath}/${displayId}`);
  }

  /**
   * Returns screen history changes
   */
  getDisplayHistory(screenId: number): Observable<ApiResponse<DisplayHistory>> {
    return this.http.get(`${this.servicePath}/${screenId}/history`);
  }

  /**
   * Sets note for selected screen
   */
  setDisplayNote(screenId: number, note: ScreenHealthNote): Observable<ScreenHealthNote> {
    return this.http.post(`${this.servicePath}/${screenId}/comment`, note) as any;
  }

  getThemes(): Observable<ApiResponse<any>> {
    const params = new UrlParams();
    return this.http.get(`${this.servicePath}/themes`, params);
  }

  getSizes(): Observable<ApiResponse<any>> {
    const params = new UrlParams();
    return this.http.get(`${this.servicePath}/sizes`, params);
  }

  getPlaces(): Observable<ApiResponse<any>> {
    const params = new UrlParams();
    return this.http.get(`${this.servicePath}/places`, params);
  }

  getModels(): Observable<ApiResponse<any>> {
    const params = new UrlParams();
    return this.http.get(`${this.servicePath}/models`, params);
  }
}
